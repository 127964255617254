import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Projects.css';

const Projects = ({ setIsHovered }) => {
  const [hoveredProject, setHoveredProject] = useState(null);
  const [imagePosition, setImagePosition] = useState(0);
  const videoRef = useRef(null);

  const personalProjects = [
    { name: 'adam: generative computer-aided design', subtitle: 'AI-powered CAD tool', url: 'https://www.makewithadam.com/', video: '/adam.mov' },
    { name: 'dreamcrafter: a vr gaussian-splatting editor', subtitle: 'VR 3D scene editor', url: 'https://dream-crafter.github.io/', video: '/dreamcrafter.mov' },
    { name: "act-2: transformer for actions", subtitle: 'foundational action model', url: 'https://www.adept.ai/blog/experiments', video: '/walking-tour-bing (1440p).mov' },
  ];

  const pastProjects = [
    { name: 'junctionx uk: a global scale hackathon', url: 'https://www.hackjunction.com/' },
    { name: 'excode: free coding bootcamp at the university of exeter', url: 'https://excode.co.uk/' },
  ];
  
  useEffect(() => {
    if (hoveredProject && hoveredProject.video && videoRef.current) {
      const video = videoRef.current;
      
      if (video.src !== hoveredProject.video) {
        video.src = hoveredProject.video;
        video.load();
        
        const playVideo = () => {
          if (!video.paused) return;
          video.play().catch(error => {
            if (error.name !== 'AbortError') {
              console.error("Error playing video:", error);
            }
          });
        };
  
        video.addEventListener('loadedmetadata', playVideo);
        return () => {
          video.removeEventListener('loadedmetadata', playVideo);
        };
      }
    }
  }, [hoveredProject]);

  const handleProjectClick = (event, project) => {
    if (project.pdfUrl) {
      event.preventDefault();
      const pdfPath = process.env.NODE_ENV === 'production'
        ? `${process.env.PUBLIC_URL}${project.pdfUrl}`
        : project.pdfUrl;
      console.log('Attempting to open PDF:', pdfPath);
      window.open(pdfPath, '_blank', 'noopener,noreferrer');
    }
  };


  const handleMouseEnter = (project, event, index) => {
    if (project !== hoveredProject) {
      setIsHovered(true);
      setHoveredProject(project);
      const topPosition = Math.min(10 + (index * 15), 70);
      setImagePosition(topPosition);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredProject(null);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div className='spacing-large project-container'>
      <div>
        <h3>recent projects</h3>
        <ol className='spacing-medium'>
{personalProjects.map((project, index) => (
  <li key={index} className='spacing-small project-item'>
   <a
  href={project.url || project.pdfUrl}
  onMouseEnter={(event) => handleMouseEnter(project, event, index)}
  onMouseLeave={handleMouseLeave}
  onClick={(event) => handleProjectClick(event, project)}
  className="links"
  target="_blank"
  rel="noopener noreferrer"
>
  {project.name}
</a>
    <video 
  className="mobile-video"
  loop
  muted
  playsInline
  autoPlay
  preload="auto"
  src={project.video}
  onError={(e) => console.error(`Error loading video: ${project.video}`, e)}
  poster={`/poster-${project.name.split(':')[0].trim()}.jpg`}
  onLoadedData={(e) => e.target.play().catch(error => console.error("Error playing video:", error))}
>
  Your browser does not support the video tag.
</video>
  </li>
))}
        </ol>
      </div>
  
      <div className='spacing-large'>
        <h3>past projects</h3>
        <ol className='spacing-medium'>
        {pastProjects.map((project, index) => (
  <li key={index} className='spacing-small'>
    <Link
      to={project.url}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} 
      className="links past-project-link"
    >
      {project.name}
    </Link>
  </li>
))}
        </ol>
      </div>
      {hoveredProject && (
  <div 
    className={`project-image ${hoveredProject ? 'visible' : ''}`}
    style={{ top: `${imagePosition}%` }}
  >
    {hoveredProject.video && (
      <video 
        ref={videoRef}
        loop
        muted
        playsInline
        src={hoveredProject.video}
      />
    )}
  </div>
)}
    </div>
  );
}

export default Projects;